<!--
File: Report_2_RoadsList.vue
Description: show list of roads entered with filtering for printing.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown :label="$t('label.select_region')" v-model="selectedRegion"
            :initial_value="selectedRegion" @input='reloadData' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <DepsDropdown :label="$t('label.select_deu')" v-model="selectedDeu"
            :initial_value="selectedDeu" :region_id="selectedRegion" @input='reloadData' :disabled='!selectedRegion' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RoadClassesDropdown :label="$t('road_network.road_class')" v-model="selectedRoadClass"
            :initial_value=selectedRoadClass @input='reloadData' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-datepicker v-model="selectedDate" md-immediately :md-open-on-focus="false">
            <label>{{ $t('label.select_date') }}</label>
          </md-datepicker>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="print" :disabled="btnDisabled"> {{ $t('buttons.print') }}
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner" class="md-progress-spinner" :md-diameter="70" md-mode="indeterminate" />
      <md-table class="paginated-table table-striped table-hover" :value="report" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')"
          :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell v-if="!selectedRegion" style="max-width: 200px;" :md-label="$t('road_network.region')"
            md-sort-by="region_description">
            {{ item.region_description }}
          </md-table-cell>
          <md-table-cell v-if="!selectedDeu" :md-label="$t('road_network.dep')" md-sort-by="deu_description">
            {{ deuLabel + '-' + item.deu_description }}
          </md-table-cell>
          <md-table-cell v-if="!selectedRoadClass" :md-label="$t('road_network.road_class')" md-sort-by="road_class">
            {{ item.road_class }}
          </md-table-cell>
          <md-table-cell style="min-width: 35%;" :md-label="$t('road_network.road')" md-sort-by="road_description">
            <a href='#' @click.stop.prevent='showRoad(item)'> {{ item.road_description }}</a>
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.start_km')" md-sort-by="start_km" md-numeric>
            {{ item.start_km | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.end_km')" md-sort-by="end_km" md-numeric>
            {{ item.end_km | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.length_km')" md-sort-by="length_km" md-numeric>
            {{ item.length_km | numFormat(3) }}
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>
    <md-card-actions md-alignment="space-between">
      <div> {{ $t('road_network.total_length') }} </div>
      <div> {{ total_length }} </div>
    </md-card-actions>
  </md-card>
</template>
<script>
import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
import DepsDropdown from '../Dropdowns/DepsDropdown.vue'
import RoadClassesDropdown from '../Dropdowns/RoadClassesDropdown.vue'
import { numFormat } from "@/store/refdata/format_helpers"
import { customSortMixin } from '@/mixins/customSortMixin'
import printReport from '@/mixins/printMixin'

export default {
  name: 'roads-list',
  mixins: [customSortMixin, printReport],
  data() {
    return {
      selectedRegion: null,
      selectedDeu: null,
      selectedRoadClass: null,
      selectedDate: new Date(),
      btnDisabled: true,
      deuLabel: '',

      showSpinner: false,
      currentSort: 'region_description',
      currentSortOrder: 'asc'
    }
  },
  components: {
    RegionsDropdown,
    DepsDropdown,
    RoadClassesDropdown
  },

  mounted() {
    this.showSpinner = true
    this.deuLabel = this.$t('road_network.dep')
  },

  methods: {
    reloadData() {
      this.showSpinner = true
      this.btnDisabled = true
      const params = {
        inserted_date: this.selectedDate.toISOString().substring(0, 10),
        region_id: this.selectedRegion,
        deu_id: this.selectedDeu,
        road_class: this.selectedRoadClass
      }
      this.$store.dispatch('REPORT_2_ROAD_INFO', params).then(() => {
        this.btnDisabled = false
        this.showSpinner = false
      })
    },
    print() {
      this.showSpinner = true
      let titleFiltersArray = [];
      let tableHeadersArray = ['<tr>'];

      // Формируем строки фильтров и заголовков
      if (this.selectedRegion) {
        titleFiltersArray.push(this.$t('label.from_region', { name: this.report[0].region_description}));
      } else {
        tableHeadersArray.push(`<th>${this.$t('road_network.region')}</th>`);
      }

      if (this.selectedDeu) {
        titleFiltersArray.push(`${this.$t('road_network.dep')}: ${this.report[0].deu_description}`);
      } else {
        tableHeadersArray.push(`<th>${this.$t('road_network.dep')}</th>`);
      }

      if (this.selectedRoadClass) {
        titleFiltersArray.push(`${this.$t('road_network.road_class')}: ${this.report[0].road_class}`);
      } else {
        tableHeadersArray.push(`<th>${this.$t('road_network.road_class')}</th>`);
      }

      const titleFilters = titleFiltersArray.length > 0 ? `(${titleFiltersArray.join(', &nbsp;')})` : '';
      const prtHtml = `<h4 style='text-align:center'>${this.$t('label.reports_title')}</h4>
        <h4 style='text-align:center'>${this.$t('label.report2_title')}
        &nbsp; ${this.$t('label.as_on')} ${this.selectedDate.toLocaleDateString()}&nbsp;${titleFilters}
        </h4>`;

      // Формируем заголовки таблицы
      tableHeadersArray.push(
        `<th>${this.$t('road_network.road')}</th>`,
        `<th>${this.$t('road_network.start_km')}</th>`,
        `<th>${this.$t('road_network.end_km')}</th>`,
        `<th>${this.$t('road_network.length_km')}</th>`,
        '</tr>'
      );

      // Генерация строк таблицы
      const tableRowsArray = this.report.map(item => {
        const row = [];
        if (!this.selectedRegion) row.push(`<td>${item.region_description}</td>`);
        if (!this.selectedDeu) row.push(`<td>${this.deuLabel}-${item.deu_description}</td>`);
        if (!this.selectedRoadClass) row.push(`<td>${item.road_class}</td>`);

        row.push(
          `<td>${item.road_description}</td>`,
          `<td class='numCell'>${numFormat(item.start_km, 3)}</td>`,
          `<td class='numCell'>${numFormat(item.end_km, 3)}</td>`,
          `<td class='numCell'>${numFormat(item.length_km, 3)}</td>`
        );

        return `<tr>${row.join('')}</tr>`;
      });

      // Добавляем итоговую строку
      tableRowsArray.push(`<tr>
          <th colspan=${this.col_span}>${this.$t('road_network.total_length')}</th>
          <th class='numCell'>${this.total_length}</th>
        </tr>`);

      // Объединяем строки в итоговый результат
      const tableHeaders = tableHeadersArray.join('');
      const tableRows = tableRowsArray.join('');
      this.printReport(prtHtml, tableHeaders, tableRows)
      this.showSpinner = false
    },

    showRoad(item) {
      const hist = {
        searchQuery: item.road_description,
        perPage: 10,
        currentPage: 1
      }
      this.$store.dispatch('SAVE_HISTORY', { data: hist, for: 'roads' })
      this.$store.dispatch('SET_USE_HISTORY')
      this.$router.push('/inventory_data/roads')
    },
  },

  computed: {
    report() {
      return this.customSort(this.$store.state.Reports.report_2_data, 'deu_description')
    },
    total_length() {
      return numFormat(this.report.reduce((acc, currValue) => acc + currValue.length_km, 0,), 3)
    },
    col_span() {
      let res = 6
      if (this.selectedRegion) res--
      if (this.selectedDeu) res--
      if (this.selectedRoadClass) res--

      return res
    }
  },

  watch: {
    selectedDate(value) {
      if (!value) this.selectedDate = new Date()
      else this.reloadData()
    },
  }
}
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.paginated-table table>tbody>tr>td {
  height: 36px;
  padding: 0px !important;
  padding-right: 24px !important;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 12;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>

</style>